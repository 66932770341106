import logo from './logo.svg';
import './App.css';


function App() {
  return (
      <div className='p-14 px-6 sm:px-16 bg-background-mobile lg:bg-background-gif w-screen h-screen bg-cover bg-no-repeat bg-center flex flex-wrap flex-col justify-between' >
        <div className='flex flex-row justify-between text-white'>
          <p className='font-dmMono'>Projects</p>
          <p className='font-dmMono text-[#ff6b00] text-xl'>[D/G]</p>
          <p className='font-dmMono'>Master</p>
        </div>
        <div className='flex flex-row justify-between text-white text-center'>
          <p className='font-dmMono'>Creative team based in Valencia since 2010</p>
        </div>
      </div>
  );
}

export default App;
